.grid-divider {
  overflow-x: hidden;
  position: relative;
}

.grid-divider > [class*="col-"]:after {
  content: '';
  background: $secondary;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    width: 100%;
    height: 1px;
    left: 0;
    top: -24px;
  }
  @media (min-width: 768px) {
    width: 1px;
    height: auto;
    left: -1px;
  }
}

.divider {
  width: 1px;
  height: auto;
  left: -1px;
  background: $secondary;
  margin-top: 30px;
  margin-bottom: 30px;
}

.flex-item {
  flex: 1;
}