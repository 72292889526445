.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  font-size: inherit;
  top: 28px;
  left: 10px;
  z-index: 2;
  color: #333;
  box-shadow: none;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  top: 28px;
  right: 10px;
  z-index: 2;
  color: #333;
  box-shadow: none;
}
