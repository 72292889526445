$spacers: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Loop through margin and padding properties
    @each $prop, $abbrev in (margin: m, padding: p) {
      // Generate global classes for all sizes
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }

      // Generate negative margin classes
      @if $prop == margin {
        @each $size, $length in $spacers {
          @if $size != 0 {
            .#{$abbrev}#{$infix}-n#{$size} {
              #{$prop}: -$length !important;
            }
            .#{$abbrev}t#{$infix}-n#{$size},
            .#{$abbrev}y#{$infix}-n#{$size} {
              #{$prop}-top: -$length !important;
            }
            .#{$abbrev}r#{$infix}-n#{$size},
            .#{$abbrev}x#{$infix}-n#{$size} {
              #{$prop}-right: -$length !important;
            }
            .#{$abbrev}b#{$infix}-n#{$size},
            .#{$abbrev}y#{$infix}-n#{$size} {
              #{$prop}-bottom: -$length !important;
            }
            .#{$abbrev}l#{$infix}-n#{$size},
            .#{$abbrev}x#{$infix}-n#{$size} {
              #{$prop}-left: -$length !important;
            }
          }
        }
      }
    }
  }
}

// Global classes for xs breakpoint (no infix)
@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}
