// Modals

.modal-header,
.modal-footer {
  background: $gray-100;
}

.modal-footer {
  @include border-bottom-radius($border-radius);
}

.modal-dialog {
  box-shadow: $box-shadow-lg-default;
  @include border-radius($border-radius);
}
.ant-modal-wrap {
  height: 100vh;
  overflow: hidden !important;
  display: block;
}
.ant-modal {
  height: calc(100vh - 16px);
  top: 0px;
}

.ant-modal .ant-modal-title,
.ant-modal .ant-modal-content {
  background-color: #f1f2f4;
}
.ant-modal-content {
  padding: $table-cell-padding !important;
  height: calc(100vh - 16px);
  max-height: calc(100vh - 16px);
  overflow: auto;
}

.ant-modal-body {
  box-shadow: 0px 1px 1px #091e4240, 0px 0px 1px #091e424f;
  padding: $table-cell-padding !important;
  @include border-radius($border-radius);
  background-color: #ffffff;
}

@media (min-width: 767px) {
  .ant-modal {
    height: unset;
    top: 10%;
  }
  .ant-modal-content {
    max-height: 70vh;
    height: 70vh;
    overflow: auto;
  }
}
