.sponsor-tree  {
  background: transparent;

  .ant-tree-treenode {
    // border-bottom: solid 1px #eee;
    padding: 0;

    &:first-child {
      .ant-tree-switcher {
        display: none;
      }
    }
  }

  .ant-tree-node-content-wrapper {
    width: 100%;
  }

  .sponsor-tree-client-name, .placement-tree-client-name {
    padding: 6px 12px;
  }

  .sponsor-tree-client-details,
  .placement-tree-client-details {
    flex-direction: row;
    align-items: center;
    @media (max-width: 800px) {
      flex-direction: column;
       align-items: flex-start;
    }
  }

  .placement-tree-result {
    flex: 1;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.sponsor-tree-card, .placement-tree-card {
  width: 100%;
  @media (max-width: 600px) {
    width: fit-content;
  }
}

.upline-tree  {
  .ant-tree-treenode {
    border-bottom: solid 1px #eee;

    .ant-tree-switcher {
      display: none;
    }
  }
}
