.techbase-dialog-full_screen {
  margin: 15px !important;
  max-width: 100vw !important;
}

.techbase-dialog {
  box-shadow: none !important;
}

.techbase-dialog-content {
  max-height: 82vh;
  overflow-y: auto;
  box-shadow: 0 0.76875rem 2.4875rem rgba(52, 58, 64, 0.3),
    0 1.3375rem 1.70625rem rgba(52, 58, 64, 0.3),
    0 0.55rem 0.53125rem rgba(0, 0, 0, 0.05),
    0 0.225rem 0.4375rem rgba(52, 58, 64, 0.3) !important;
}
