// Header Base
.mobile-header-content__right {
  display: flex;
}
.app-header {
  height: $app-header-height;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 10;
  transition: all 0.2s;

  &.header-shadow {
    box-shadow: $box-shadow-default;
  }

  .app-header__content {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 0 $layout-spacer-x;
    height: $app-header-height;

    .app-header-content__left {
      display: flex;
      align-items: center;
    }

    .app-header-content__right {
      align-items: center;
      display: flex;
    }
  }

  .header-user-info {
    & > .widget-heading,
    & > .widget-subheading {
      white-space: nowrap;
    }

    & > .widget-subheading {
      font-size: $font-size-xs;
    }
  }
}

.app-header__heading {
  flex: 1;
  display: flex;
  align-items: center;
  align-content: center;
}

.app-header__page-name {
  flex: 1;
  font-size: 1.2rem;
  text-align: center;
}

.app-header__logo {
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  width: $app-sidebar-width;
  display: flex;
  align-items: center;
  transition: width 0.2s;

  .logo-src {
    height: $logo-height;
    width: $logo-width;
    background: url("../../../assets/TemplateImages/logo-inverse.png");
  }

  img {
    height: $app-header-height;
    width: 100%;
    object-fit: contain;
  }
}

.app-header__menu,
.app-header__mobile-menu {
  display: none;
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  align-items: center;
}

/* Tooltip Styles */
.custom-tooltip .tooltip-inner {
  background-color: #343a40; /* Dark background */
  color: #fff; /* White text */
  padding: 10px; /* Add some padding */
  border-radius: 6px; /* Rounded corners */
}

.custom-tooltip .arrow::before {
  border-top-color: #343a40; /* Match background color */
}

// Header Modifiers

@import "modifiers/fixed-header";
@import "modifiers/header-dots";
@import "modifiers/header-megamenu";
@import "modifiers/header-buttons";
//@import "modifiers/header-horizontal";

// Header Themes

@import "themes/header-light";
@import "themes/header-dark";
//@import "themes/header-inverted";
