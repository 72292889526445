.ant-btn.upgrade-button {
  // background-color:#ffec3d;
  border: none;
  color: #000;
  font-size: 16px;
  padding: 18px 0;
  width: 150px;
}

.ant-btn.upgrade-button:hover {
  // background-color:#fadb14;
  color: #000;
}

.ant-btn.upgrade-button:hover {
  // background-color:#fadb14;
  color: #000;
}