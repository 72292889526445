.product-images-slider {
  .swiper-slide {
    padding-top: 100%;
    overflow: hidden;
    position: relative;
    border: 1px solid #c0bfbf;

    img {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translate(0, -50%);
    }
  }

  .swiper-button-prev {
    left: 0;
    color: #ffffff66;
  }

  .swiper-button-next {
    right: 0;
    color: #ffffff66;
  }
}

.product-images-slider-thumbs {
  .swiper-slide {
    cursor: pointer;
    border: 1px solid #c0bfbf;

    &-thumb-active {
      border-color: #ff0000;
    }
  }

  &-wrapper {
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translate(0, -50%);
    }
  }
}