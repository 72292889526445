@import "scss/variables";
@import "scss/reset";

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1;
  min-height: 580px;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: $out-of-range-color;
}

.rbc-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  flex: 1 0;
  flex-basis: auto !important;

  > a {
    &,
    &:active,
    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }
}

.rbc-row-content {
  position: relative;
  user-select: none;
  z-index: 4;
}

.rbc-today {
  background-color: $today-highlight-bg;
}

@import "scss/toolbar";
@import "scss/event";
@import "scss/month";
@import "scss/agenda";
@import "scss/time-grid";
